<template>
  <v-layout column class="mx-auto px-15 login">
    <v-layout v-show="showToastVerify" class="confirm-toast">
      <span class="py-3 text-center text-body-2 white--text">Your email has been confirmed. Please sign in to continue.</span>
    </v-layout>
    <h5 class="mb-2 text-h5">
      Sign In
    </h5>
    <p class="mb-6 text-subtitle-2">
      Not a member? <NuxtLink to="/sign-up" class="primary--text">
        Create an account
      </NuxtLink>
    </p>
    <v-form ref="form" class="mb-15" @submit.prevent="userLogin">
      <label class="text-subtitle-2">Email</label>
      <v-text-field
        v-model="formData.email"
        :rules="[$formRules.required]"
        dense
        outlined
        placeholder="Enter your email"
        class="mt-1"
      />

      <v-flex class="d-flex">
        <label class="flex text-subtitle-2">Password</label>
        <NuxtLink tabindex="-1" to="/forgot-password" class="my-auto text-caption primary--text">
          Forgot Password?
        </NuxtLink>
      </v-flex>
      <v-hover v-slot="{ hover }">
        <v-text-field
          v-model="formData.password"
          :rules="[$formRules.required]"
          :type="isShowPassword ? 'text' : 'password'"
          dense
          outlined
          placeholder="Enter your password"
          class="mt-1"
        >
          <template #append>
            <v-icon v-show="hover" tabindex="-1" @click="isShowPassword = !isShowPassword">
              {{ isShowPassword ? 'visibility' : 'visibility_off' }}
            </v-icon>
          </template>
        </v-text-field>
      </v-hover>

      <v-btn
        x-large
        depressed
        type="submit"
        width="100%"
        class="mt-4 primary"
      >
        Sign In
      </v-btn>

      <v-layout class="my-5">
        <v-divider class="my-auto" />
        <span class="mx-2 text-subtitle-1 secondary--text">Or</span>
        <v-divider class="my-auto" />
      </v-layout>

      <!-- <googleSignIn
        :client-id="$config.ggClientId"
        :success-call-back="getSuccessData"
        :failure-call-back="getFailureData"
      /> -->

      <!-- <v-btn @click.prevent="$auth.loginWith('social')">
        Login with social
      </v-btn> -->

      <v-btn
        x-large
        depressed
        width="100%"
        color="#CD5542"
        class="mb-3 white--text justify-start"
        @click="ggLogin"
      >
        <v-icon size="28" class="mr-4">
          mdi-google
        </v-icon>
        Continue with Google
      </v-btn>

      <v-btn
        x-large
        depressed
        width="100%"
        color="#415A93"
        class="white--text justify-start"
        @click="facebookLogin"
      >
        <v-icon size="28" class="mr-4">
          mdi-facebook
        </v-icon>
        Continue with Facebook
      </v-btn>
    </v-form>
  </v-layout>
</template>

<script>
// import googleSignIn from 'google-signin-vue/src/googleSignIn.vue'
import { mapState } from 'vuex'
import { omit } from 'lodash'
import { popup } from '@/utils/index'
import recaptchaMixin from '@/plugins/mixins/recaptcha'
// const popupTools = require('popup-tools')

export default {

  components: {
    // googleSignIn
  },

  mixins: [recaptchaMixin],

  layout: 'authentication',

  middleware({ redirect, $helpers, query, $auth }) {
    const auth = $helpers.get($auth, '$state', {})
    const user = $helpers.get(auth, 'user')
    const isLoggedIn = $helpers.get(auth, 'loggedIn')
    if (query.go_sign_up) {
      redirect('/sign-up', omit(query, ['go_sign_up']))
    }
    if (isLoggedIn && $helpers.get(user, 'role') !== 'guest') {
      redirect('/')
    }
  },

  auth: false,

  data: () => ({
    isShowPassword: false,
    formData: {},
    authRedirect: null,
    // ggClientId: $config.ggClientId,
    type: 'login',
    showToastVerify: false,
    showPopupLoginSignUp: false
  }),

  head() {
    return {
      title: 'Login',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Login Stream Studio'
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: 'Login Stream Studio'
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          property: 'apple-mobile-web-app-title',
          content: 'Login Stream Studio'
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'Login Stream Studio'
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        }
      ]
    }
  },
  computed: {
    ...mapState(['auth', 'loginRedirect'])
  },

  watch: {
    'auth.loggedIn'(isLoggedIn) {
      if (isLoggedIn && this.$helpers.get(this.auth, 'user.role') !== 'guest') {
        this.loginRedirect.path = this.loginRedirect.path || '/'
        this.$router.push(this.loginRedirect)
        this.$store.commit('setLoginRedirect', {
          path: '',
          query: {}
        })
      }
    }
  },

  created() {
    const isVerify = !!this.$route.query.verify
    this.$helpers.updateQueryUrl(this, { verify: '' })
    if (isVerify) {
      this.showToastVerify = true
      setTimeout(() => {
        this.showToastVerify = false
      }, 3000)
    }
  },

  mounted() {
    this.authRedirect = this.$auth.$storage.getUniversal('redirect') || '/'
    const redirectData = {
      path: this.authRedirect
    }
    if (Object.keys(this.$route.query).length) {
      redirectData.query = this.$route.query
    }
    this.$store.commit('setLoginRedirect', redirectData)
    const { oauthToken } = this.$route.query
    if (oauthToken) {
      this.loginWithOauthToken()
    }
  },

  methods: {
    catchErrorMsg(error) {
      const message = this.$helpers.apiErrorMessage(error)
      if (message === 'Expired user') {
        this.$store.commit('setLogginEmail', this.formData.email)
        this.$router.push({ name: 'resend-verify-email' })
      } else {
        this.$notify.error({ title: 'Login', message })
      }
    },
    async userLogin() {
      const ok = this.$refs.form.validate()
      if (!ok) { return }
      const token = await this.getRecaptchaToken('signup')
      try {
        await this.$auth.loginWith('local', {
          data: {
            ...this.formData,
            recaptchaResponse: token
          }
        })
        this.$notify.success({
          title: 'Login',
          message: 'Successfully!'
        })
        this.fetchUser()
      } catch (error) {
        this.catchErrorMsg(error)
      }
      await this.resetRecaptcha()
    },
    async ggLogin() {
      const res = await popup(this.$config.apiUrl + '/auth/google')
      // console.log('res', res)
      try {
        const data = await this.$axios.$get('/auth/google/callback?' + res.query)
        // console.log('data', data)
        // this.$auth.setUser(data.data.user)
        await this.$auth.setUserToken(data.token)
        this.fetchUser()
      } catch (error) {
        this.catchErrorMsg(error)
      }
    },
    async facebookLogin() {
      const res = await popup(this.$config.apiUrl + '/auth/facebook', true)
      try {
        const data = await this.$axios.$get('/auth/facebook/callback?code=' + res.query.code)
        // console.log('data', data)
        // this.$auth.setUser(data.data.user)
        await this.$auth.setUserToken(data.token)
        this.fetchUser()
      } catch (error) {
        this.catchErrorMsg(error)
      }

      // const self = this
      // popupTools.popup(this.$config.apiUrl + '/auth/facebook', 'Facebook Connect', {}, async function (err, res) {
      //   if (err) {
      //     console.log('err', err)
      //     self.$notify.error({
      //       title: 'Login',
      //       message: self.$helpers.apiErrorMessage(err.message)
      //     })
      //   } else {
      //     console.log('user', res)
      //     if (res.auth && res.auth.query) {
      //       const data = await self.$axios.$get('/auth/facebook/callback?' + res.auth.query)
      //       // console.log('data', data)
      //       // self.$auth.setUser(data.data.user)
      //       console.log('data', data)
      //       self.$auth.setUserToken(data.token)
      //     }
      //   }
      // })
    },

    async loginWithOauthToken() {
      try {
        const { oauthToken } = this.$route.query
        if (!oauthToken) { return }

        const data = await this.$axios.$get('/oauth/session', {
          headers: {
            Authorization: `Bearer ${oauthToken}`
          }
        })
        this.$helpers.updateQueryUrl(this, { oauthToken: '' })
        await this.$auth.setUserToken(data.token)
        this.fetchUser()
      } catch (error) {
        this.catchErrorMsg(error)
      }
    },

    async fetchUser() {
      this.$auth.$storage.setUniversal('redirect', this.authRedirect)
      await this.$auth.fetchUser()
    }

    // getSuccessData (user) {
    //   // The User variable contains id_token, id, name, image, email as objects.
    //   // Send The id_token to the Backend for the verication.
    // },
    // getFailureData (errorData) {
    //   // The errorData variable contains failure details
    // }
  }

  // head () {
  //   return {
  //     // meta: [
  //     //   {
  //     //     name: 'google-signin-scope',
  //     //     content: 'profile email'
  //     //   },
  //     //   {
  //     //     name: 'google-signin-client_id',
  //     //     content: $config.ggClientId
  //     //   }
  //     // ],
  //     script: [
  //       {
  //         src: 'https://apis.google.com/js/platform.js',
  //         defer: true,
  //         async: true
  //       }
  //     ]
  //   }
  // }
}
</script>

<style lang="scss">
.login {
  margin-top: 160px;
  max-width: 570px;
  .confirm-toast {
    background: #56BE8C;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
  }
}
</style>
